/* Webfont: AngryMonkeySimple */@font-face {
  font-family: 'AngryMonkeySimple';
  src: url('./font/AngryMonkeySimple.woff2') format('woff2'), /* Modern Browsers */
       url('./font/AngryMonkeySimple.otf') format('opentype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: AngryMonkeyBase */@font-face {
  font-family: 'AngryMonkeyBase';
  src: url('./font/AngryMonkeyBlack.woff2') format('woff2'), /* Modern Browsers */;
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Academic-M54";
  src: url("./font/ACADEMIC_M54.TTF");
  font-style: normal;
}

@font-face {
  font-family: "arco";
  src: url("./font/ARCO.ttf");
  font-style: normal;
}

@font-face {
  font-family: "brln";
  src: url("./font/BRLNSR.TTF");
  font-style: normal;
}

#root {
  background-color: #000;
}

#roadmap {
  background-color: #fff;
}

.grid.bg-white {
  background-color: #fff;
}

/*
body {
  margin: 0 auto !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
  overflow-x: hidden;
  background: url(/src/images/sloths-bg.png) no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-width: 1400px;
}

code {
  font-family: "brln", sans-serif;
}

:root {
  --main-color: #6891ed;
  --text-color: #e7e7e7;
  --blue: #6891ed;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

/* h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  padding: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "arco" !important;
}

p {
  font-family: "brln" !important;
  letter-spacing: 1px;
} */

.small-font {
  font-size: 16px !important;
}

.active {
  /* background: var(--main-color); */
  /* border-radius: 8px; */
}
.middeling {
  display: flex;
  justify-content: center;
  align-items: center;
}
hr {
  margin: 25px 0;
  color: var(--text-color) !important;
}
.link {
  color: var(--main-color);
}
.link:hover {
  color: var(--text-color);
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

div.dICzHf {
  background-color: rgb(162, 230, 245) !important;
}

div.hjFQaG {
  color: rgb(162, 230, 245) !important;
}

.cUhDWx {
  fill: rgb(162, 230, 245) !important;
}

button#wallet-connect-trustwallet,
button#wallet-connect-mathwallet,
button#wallet-connect-tokenpocket,
button#wallet-connect-walletconnect,
button#wallet-connect-binance\ chain\ wallet,
a.sc-jSgvzq.sc-bYEvvW.gOOvqZ.eHcJpX.sc-ehSDrC.jxUsPl,
a.sc-jSgvzq.sc-bYEvvW,
a.dLsgSS,
a.fhEmnm,
a.sc-iktFfs.sc-gGmKaI.gPmXIV.ecZwoj,
a.sc-iktFfs.sc-gGmKaI.gzTNyQ.fiFWWQ,
a.sc-iktFfs.sc-gGmKaI.jIvnOv.fiFWWQ.sc-euMpEg.cZaQIZ,
a.sc-iktFfs.sc-gGmKaI.EcPtm.ecZwoj.sc-euMpEg.duCwjx,
a.sc-jJEKmz.sc-clsFYl.DCnDq.jfeJAO.sc-fHuLxr.hWKZWP,
a.sc-jJEKmz.sc-clsFYl.fELdvN.jfeJAO,
a.sc-jJEKmz.sc-clsFYl.hSmEYX.hlmKQd.sc-fHuLxr.icwMnL,
.sc-jNMcJZ.eYGrWk a,
a.sc-fubCzh.sc-jJEKmz.lpsUPj.mhjY.sc-clsFYl.dpwxSR {
  display: none !important;
}

.sc-eggMyH,
.gxBphU,
.kwzbpv,
.cgllQK {
  border-bottom: none !important;
}

.jCGkwA,
.jXGSEw,
.hxWjzo,
.icJZux {
  background-color: var(--blue) !important;
  opacity: 0.5 !important;
}

.dRkkFJ,
.dMWeoW,
.enGsZK,
.cRhUol {
  border: 2px solid var(--blue) !important;
  color: #fff !important;
}

.cKrdj,
.gaOJzr,
.gZgwsy,
.eWygAy,
.jtSYNp,
.boCcds,
.dLsgSS,
.bcTHOB,
.hufYrk {
  fill: var(--blue) !important;
}

.gbnmUs,
.blAmLo,
.ldHeLF,
.gUPczV,
.cFaosz,
.gmXJlb,
.DFvIQ,
.kCMxsx {
  color: var(--blue) !important;
}

.fopDDk,
.iNrdsl {
  color: #fff !important;
  font-family: "AngryMonkeySimple" !important;
  font-size: 20px !important;
}

.wallet_button.not-connected,
.wallet_button.connected {
  font-size: 20px !important;
}

.modal-header .btn-close {
  color: #fff !important;
}

@media only screen and (max-width: 768px) {
  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox,
  select,
  option {
    -webkit-appearance: none;
  }
}

/* NEW NEW NEW */
@font-face {
  font-family: "Century-gothic";
  src: url("./font/Century\ Gothic.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Century-gothic-bold";
  src: url("./font/Century\ Gothic\ Bold.otf");
  font-weight: bold;
}

:root {
  --primary: #fff;
  --white: #fff;
  --lightGray: #ebebeb;
  --maxWidth: 1110px;
  --md: 1023px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "AngryMonkeySimple", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
  color: var(--primary);
  font-size: 1.8rem;
  line-height: 35px;
}

p {
  margin: 0 0 2rem;
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AngryMonkeySimple", sans-serif;
}

h1 {
  font-size: 5.4rem;
}

h2 {
  font-size: 4.5rem;
  letter-spacing: 2.5px;
  margin-bottom: 3rem;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.globalWrapper {
  max-width: calc(var(--maxWidth) + 40px);
  margin: 0 auto;
  padding: 0 25px;
}
.globalWrapper.fixed {
  position: fixed;
  width: 100%;
  background: black;
  max-width: 100%;
}

#hero {
  padding-top: 120px;
}
.menu-open {
  overflow: hidden;
  position: fixed;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.link-dark {
  color: #353535;
}

@media all and (max-width: 1023px) {
  p {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
}

.snow,
.winter-is-coming {
  z-index: 100;
  pointer-events: none;
}

.winter-is-coming {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.snow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: falling linear infinite both;
  animation: falling linear infinite both;
  transform: translate3D(0, -100%, 0);
}
.snow--near {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");
  background-size: contain;
}
.snow--near + .snow--alt {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}
.snow--mid {
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png");
  background-size: contain;
}
.snow--mid + .snow--alt {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}
.snow--far {
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png");
  background-size: contain;
}
.snow--far + .snow--alt {
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
}

@-webkit-keyframes falling {
  0% {
    transform: translate3D(-7.5%, -100%, 0);
  }
  100% {
    transform: translate3D(7.5%, 100%, 0);
  }
}

@keyframes falling {
  0% {
    transform: translate3D(-7.5%, -100%, 0);
  }
  100% {
    transform: translate3D(7.5%, 100%, 0);
  }
}
